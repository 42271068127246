import React from "react";
import { Button } from "../ui/Button";

interface AddCustomEmailButtonProps {
  onClick: () => void;
}

export const AddCustomEmailButton: React.FC<AddCustomEmailButtonProps> = ({
  onClick,
}) => (
  <Button
    variant="secondary"
    onClick={onClick}
    className="fixed top-[4.5rem] right-4 flex items-center gap-2 bg-white/90 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 border border-purple-100 px-4 py-2 rounded-xl hover:bg-purple-50">
    <svg
      className="w-5 h-5 text-purple-600"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 4v16m8-8H4"
      />
    </svg>
    <span className="text-purple-600 font-medium">Custom Email</span>
  </Button>
);
