import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { EmailSlice, createEmailSlice } from "./slices/emailSlice";
import { BookmarkSlice, createBookmarkSlice } from "./slices/bookmarkSlice";
import { PollingSlice, createPollingSlice } from "./slices/pollingSlice";
import { StorageSlice, createStorageSlice } from "./slices/storageSlice";
import { RemarkSlice, createRemarkSlice } from "./slices/remarkSlice";

export const useStore = create<
  EmailSlice & BookmarkSlice & PollingSlice & StorageSlice & RemarkSlice
>()(
  devtools(
    (...a) => ({
      ...createStorageSlice(...a),
      ...createEmailSlice(...a),
      ...createBookmarkSlice(...a),
      ...createPollingSlice(...a),
      ...createRemarkSlice(...a),
    }),
    { name: "Email Store" }
  )
);
