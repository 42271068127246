import React from "react";
import { Button } from "../ui/Button";

interface EmptyEmailStateProps {
  onGenerate: () => void;
  loading: boolean;
}

export const EmptyEmailState: React.FC<EmptyEmailStateProps> = ({
  onGenerate,
  loading,
}) => (
  <div className="flex flex-col items-center justify-center py-12 space-y-4">
    <div className="p-4 rounded-full bg-purple-100">
      <svg
        className="w-12 h-12 text-purple-600"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    </div>
    <Button
      onClick={onGenerate}
      disabled={loading}
      loading={loading}
      className="px-8 py-4 text-lg">
      Generate Email Address
    </Button>
  </div>
);
