import React from "react";
import { Button } from "../ui/Button";

interface VerificationItem {
  value: string;
  confidence: number;
}

interface EmailAiAnalysisProps {
  isOpen: boolean;
  onClose: () => void;
  analysis: {
    links?: VerificationItem[];
    codes?: VerificationItem[];
  } | null;
  loading: boolean;
}

export const EmailAiAnalysis: React.FC<EmailAiAnalysisProps> = ({
  isOpen,
  onClose,
  analysis,
  loading,
}) => {
  if (!isOpen) return null;

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  const handleOpenLink = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const hasContent = analysis?.links?.length || analysis?.codes?.length;

  return (
    <>
      <div
        className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40"
        onClick={onClose}
      />
      <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-2xl z-50">
        <div className="bg-white/95 backdrop-blur-md shadow-2xl rounded-2xl border border-purple-100 overflow-hidden animate-fadeIn">
          <div className="h-1.5 w-full bg-gradient-to-r from-purple-500 to-pink-500" />

          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 flex items-center gap-2">
                <svg
                  className="w-5 h-5 text-purple-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Verification Info
              </h2>
              <Button
                variant="secondary"
                onClick={onClose}
                className="p-2 hover:bg-purple-50">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </Button>
            </div>

            <div className="space-y-4">
              {loading ? (
                <div className="flex flex-col items-center justify-center py-12 space-y-4">
                  <div className="w-16 h-16 relative">
                    <div className="absolute inset-0 rounded-full border-4 border-purple-100 opacity-25"></div>
                    <div className="absolute inset-0 rounded-full border-4 border-purple-500 border-t-transparent animate-spin"></div>
                  </div>
                  <p className="text-gray-500 animate-pulse">
                    Scanning for verification info...
                  </p>
                </div>
              ) : hasContent ? (
                <>
                  {/* Links Section */}
                  {analysis?.links?.map((link, index) => (
                    <div
                      key={`link-${index}`}
                      className="p-4 rounded-lg bg-purple-50/50 border border-purple-100 shadow-sm">
                      <div className="flex items-start justify-between gap-4">
                        <div className="flex-grow space-y-2">
                          <div className="flex items-center gap-2">
                            <div className="font-medium text-gray-700">
                              Verification Link
                            </div>
                            <span
                              className={`text-sm px-2 py-0.5 rounded-full ${
                                link.confidence >= 90
                                  ? "bg-green-100 text-green-700"
                                  : link.confidence >= 80
                                  ? "bg-yellow-100 text-yellow-700"
                                  : "bg-gray-100 text-gray-700"
                              }`}>
                              {link.confidence}%
                            </span>
                          </div>
                          <p className="text-gray-600 break-all font-mono text-sm">
                            {link.value}
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <Button
                            variant="secondary"
                            onClick={() => handleOpenLink(link.value)}
                            className="p-2 hover:bg-purple-100">
                            <svg
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                              />
                            </svg>
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => handleCopy(link.value)}
                            className="p-2 hover:bg-purple-100">
                            <svg
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2"
                              />
                            </svg>
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* Codes Section */}
                  {analysis?.codes?.map((code, index) => (
                    <div
                      key={`code-${index}`}
                      className="p-4 rounded-lg bg-purple-50/50 border border-purple-100 shadow-sm">
                      <div className="flex items-start justify-between gap-4">
                        <div className="flex-grow space-y-2">
                          <div className="flex items-center gap-2">
                            <div className="font-medium text-gray-700">
                              Verification Code
                            </div>
                            <span
                              className={`text-sm px-2 py-0.5 rounded-full ${
                                code.confidence >= 90
                                  ? "bg-green-100 text-green-700"
                                  : code.confidence >= 80
                                  ? "bg-yellow-100 text-yellow-700"
                                  : "bg-gray-100 text-gray-700"
                              }`}>
                              {code.confidence}%
                            </span>
                          </div>
                          <p className="text-gray-600 font-mono text-lg tracking-wide">
                            {code.value}
                          </p>
                        </div>
                        <Button
                          variant="secondary"
                          onClick={() => handleCopy(code.value)}
                          className="p-2 hover:bg-purple-100">
                          <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex flex-col items-center justify-center py-12 space-y-4 text-gray-500">
                  <svg
                    className="w-16 h-16 text-purple-200"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                  <p>No verification information found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
