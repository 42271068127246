import React from "react";
import { EmailStatus } from "../EmailStatus";

interface EmailHeaderProps {
  isExpired: boolean;
  expiresAt: string | null;
  isBookmarked: boolean;
}

export const EmailHeader: React.FC<EmailHeaderProps> = ({
  isExpired,
  expiresAt,
  isBookmarked,
}) => (
  <div className="flex items-center justify-between">
    <h3 className="text-2xl font-semibold text-gray-700">
      Your Temporary Email
    </h3>
    <EmailStatus
      isExpired={isExpired}
      expiresAt={expiresAt}
      isBookmarked={isBookmarked}
    />
  </div>
);
