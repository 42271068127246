import React from "react";
import { TimeDisplay } from "./TimeDisplay";

interface EmailStatusProps {
  isExpired: boolean | null;
  expiresAt: string | null;
  isBookmarked: boolean;
}

export const EmailStatus: React.FC<EmailStatusProps> = ({
  isExpired,
  expiresAt,
  isBookmarked,
}) => {
  return (
    <div className="flex items-center gap-3">
      {!isExpired && (
        <div className="px-3 py-1.5 text-sm font-medium text-green-600 bg-green-100 rounded-full">
          {isBookmarked ? "Bookmarked" : "Active"}
        </div>
      )}
      {expiresAt && !isExpired && !isBookmarked && (
        <div className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-purple-600 bg-purple-100 rounded-full">
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <TimeDisplay expiresAt={expiresAt} />
        </div>
      )}
    </div>
  );
};
