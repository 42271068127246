import React from "react";
import { Email } from "../types/email";
import { EmailContentDisplay } from "./email/EmailContentDisplay";
import { formatDistanceToNow } from "date-fns";
import { Button } from "./ui/Button";

interface MessageDisplayProps {
  message: Email;
  expanded?: boolean;
  onClick?: () => void;
  onAiAnalyze?: () => void;
  isAnalyzing?: boolean;
}

export const MessageDisplay: React.FC<MessageDisplayProps> = ({
  message,
  expanded = false,
  onClick,
  onAiAnalyze,
  isAnalyzing = false,
}) => {
  const timestamp = new Date(message.timestamp);

  return (
    <div
      className={`bg-white rounded-xl shadow-[0_2px_8px_-3px_rgba(0,0,0,0.07),0_2px_4px_-4px_rgba(0,0,0,0.06)] 
        hover:shadow-[0_4px_12px_-6px_rgba(0,0,0,0.1),0_4px_6px_-6px_rgba(0,0,0,0.08)] 
        transition-all duration-200
        ${
          expanded
            ? "ring-2 ring-purple-500 bg-purple-50/30"
            : "hover:scale-[1.01]"
        }
        border border-gray-200/80 hover:border-purple-200`}>
      <div className="h-1.5 w-full bg-gradient-to-r from-purple-500 to-purple-400 rounded-t-xl shadow-sm" />

      <div className="p-5">
        <div className="flex justify-between items-start mb-3">
          <div className="flex items-start space-x-3">
            <div className="w-10 h-10 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0 shadow-inner">
              <svg
                className="w-5 h-5 text-purple-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </div>
            <div>
              <h3 className="font-medium text-gray-900 text-lg mb-1">
                {message.subject}
              </h3>
              <p className="text-sm text-gray-500">From: {message.from}</p>
            </div>
          </div>
          <span className="text-sm text-gray-500 whitespace-nowrap ml-4 bg-gray-50 px-3 py-1 rounded-full shadow-sm border border-gray-100">
            {formatDistanceToNow(timestamp, { addSuffix: true })}
          </span>
        </div>

        {expanded && (
          <div className="border-t border-gray-100 pt-4 mt-4">
            <EmailContentDisplay
              rawContent={message.rawContent}
              preview={!expanded}
            />
          </div>
        )}

        <div className="mt-4 flex items-center gap-3">
          <Button
            variant="secondary"
            onClick={onClick}
            className="flex items-center gap-2 text-sm">
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
            View Full Email
          </Button>
          <Button
            variant="secondary"
            onClick={onAiAnalyze}
            disabled={isAnalyzing}
            loading={isAnalyzing}
            className="flex items-center gap-2 text-sm">
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            {isAnalyzing ? "Analyzing..." : "AI Summary"}
          </Button>
        </div>
      </div>
    </div>
  );
};
