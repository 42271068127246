import { StateCreator } from "zustand";
import { RemarkState, LoadingState } from "../../types/store";
import axios from "axios";
import { API_BASE_URL } from "../../config/constants";

export interface RemarkSlice extends RemarkState, LoadingState {
  setRemark: (email: string, remark: string) => Promise<void>;
  getRemark: (email: string) => Promise<void>;
  removeRemark: (email: string) => Promise<void>;
  bookmarkLoading: boolean;
}

export const createRemarkSlice: StateCreator<
  RemarkSlice,
  [],
  [],
  RemarkSlice
> = (set, get) => ({
  remarks: {},
  loadingRemark: false,
  loading: false,
  error: null,
  bookmarkLoading: false,

  setRemark: async (email: string, remark: string) => {
    try {
      set({ loadingRemark: true, error: null });
      await axios.post(`${API_BASE_URL}/remark`, { email, remark });
      set((state) => ({
        remarks: { ...state.remarks, [email]: remark },
      }));
    } catch (error) {
      console.error("Error setting remark:", error);
    } finally {
      set({ loadingRemark: false });
    }
  },

  getRemark: async (email: string) => {
    if (get().remarks[email]) return;

    try {
      set({ loadingRemark: true, error: null });
      const response = await axios.get(`${API_BASE_URL}/remark`, {
        params: { email },
      });
      const remark = response.data.data.remark;
      set((state) => ({
        remarks: { ...state.remarks, [email]: remark },
      }));
      return remark;
    } catch (error) {
      set({ error: "Failed to get remark" });
      throw error;
    } finally {
      set({ loadingRemark: false });
    }
  },

  removeRemark: async (email: string) => {
    try {
      set({ loadingRemark: true, error: null });
      await axios.delete(`${API_BASE_URL}/remark`, {
        data: { email },
      });
      set((state) => {
        const newRemarks = { ...state.remarks };
        delete newRemarks[email];
        return { remarks: newRemarks };
      });
    } catch (error) {
      set({ error: "Failed to remove remark" });
      throw error;
    } finally {
      set({ loadingRemark: false });
    }
  },
});
