import { useState, useCallback } from "react";
import { useStore } from "../stores/useStore";

export const useBookmarkState = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { bookmarkEmail, unbookmarkEmail, isBookmarked } = useStore();

  const handleBookmark = useCallback(
    async (email: string) => {
      if (isProcessing || !email) return;

      try {
        setIsProcessing(true);

        if (isBookmarked) {
          await unbookmarkEmail(email);
        } else {
          await bookmarkEmail(email);
        }

        // Add a small delay to allow KV to propagate
        await new Promise((resolve) => setTimeout(resolve, 500));
      } catch (error) {
        console.error("Failed to toggle bookmark:", error);
      } finally {
        setIsProcessing(false);
      }
    },
    [isProcessing, isBookmarked, bookmarkEmail, unbookmarkEmail]
  );

  return {
    isProcessing,
    handleBookmark,
  };
};
