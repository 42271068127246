import { useState } from "react";
import { useStore } from "../stores/useStore";

export const useEmailActions = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [isEditingRemark, setIsEditingRemark] = useState(false);
  const [remarkInput, setRemarkInput] = useState("");
  const { setRemark, removeRemark, currentEmail } = useStore();

  const handleCopy = async () => {
    if (currentEmail) {
      await navigator.clipboard.writeText(currentEmail);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }
  };

  const handleBookmarkToggle = async () => {
    // This is handled directly in the EmailContainer now
  };

  const handleEditStart = () => {
    setIsEditingRemark(true);
  };

  const handleEditCancel = () => {
    setIsEditingRemark(false);
    setRemarkInput("");
  };

  const handleRemarkChange = (value: string) => {
    setRemarkInput(value);
  };

  const handleRemarkSubmit = async () => {
    if (currentEmail && remarkInput.trim()) {
      await setRemark(currentEmail, remarkInput.trim());
      setIsEditingRemark(false);
      setRemarkInput("");
    }
  };

  const handleRemarkRemove = async () => {
    if (currentEmail) {
      await removeRemark(currentEmail);
    }
  };

  return {
    isCopied,
    isEditingRemark,
    remarkInput,
    handleCopy,
    handleBookmarkToggle,
    handleEditStart,
    handleEditCancel,
    handleRemarkChange,
    handleRemarkSubmit,
    handleRemarkRemove,
  };
};
