import { StateCreator } from "zustand";
import { EmailState, LoadingState } from "../../types/store";
import axios from "axios";
import { API_BASE_URL } from "../../config/constants";
import { StorageSlice } from "./storageSlice";

export interface EmailSlice extends EmailState, LoadingState {
  generateNewEmail: () => Promise<void>;
  fetchEmailContent: () => Promise<void>;
  setCurrentEmail: (email: string, expiresAt: string) => void;
  resetEmailState: () => void;
  startPolling: () => void;
  stopPolling: () => void;
  pollingTimeout: ReturnType<typeof setTimeout> | null;
  isPolling: boolean;
}

interface ExtendedStorageSlice extends StorageSlice {
  startPolling: () => void;
  stopPolling: () => void;
}

export const createEmailSlice: StateCreator<
  EmailSlice & ExtendedStorageSlice,
  [],
  [],
  EmailSlice
> = (set, get) => ({
  currentEmail: null,
  expiresAt: null,
  isBookmarked: false,
  emailContent: [],
  loading: false,
  error: null,
  bookmarkLoading: false,
  pollingTimeout: null,
  isPolling: false,

  generateNewEmail: async () => {
    try {
      set({ loading: true, error: null });
      const response = await axios.post(`${API_BASE_URL}/random-email`);
      const { email, expiresAt } = response.data.data;

      const newEmailData = {
        email,
        expiresAt,
        isBookmarked: false,
      };

      set({
        currentEmail: email,
        expiresAt,
        isBookmarked: false,
        emailContent: [],
      });

      get().saveToStorage(newEmailData);
    } catch (error) {
      set({ error: "Failed to generate email" });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  fetchEmailContent: async () => {
    const { currentEmail } = get();
    if (!currentEmail) return;

    try {
      const response = await axios.get(`${API_BASE_URL}/fetch`, {
        params: { key: currentEmail },
      });
      set({ emailContent: response.data.data });
    } catch (error) {
      set({ error: "Failed to fetch email content" });
      throw error;
    }
  },

  setCurrentEmail: (email: string, expiresAt: string) => {
    set({
      currentEmail: email,
      expiresAt,
      isBookmarked: true,
      emailContent: [],
    });

    get().saveToStorage({
      email,
      expiresAt,
      isBookmarked: true,
    });

    get().startPolling();
  },

  resetEmailState: () => {
    set({
      currentEmail: null,
      expiresAt: null,
      isBookmarked: false,
      emailContent: [],
      error: null,
    });
    get().clearStorage();
  },

  startPolling: () => {
    get().startPolling();
  },

  stopPolling: () => {
    get().stopPolling();
  },
});
