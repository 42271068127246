import React from "react";
import { useStore } from "../../stores/useStore";
import { EmailContainer } from "./EmailContainer";
import { EmptyEmailState } from "./EmptyEmailState";

export const MainEmailSection: React.FC = () => {
  const { currentEmail, loading, generateNewEmail } = useStore();

  return (
    <div className="p-8 transition-all duration-300 shadow-xl bg-white/80 backdrop-blur-lg rounded-2xl hover:shadow-2xl">
      {!currentEmail ? (
        <EmptyEmailState onGenerate={generateNewEmail} loading={loading} />
      ) : (
        <EmailContainer />
      )}
    </div>
  );
};
