import { useState } from "react";

export const useAuthForm = (
  onAuthenticate: (code: string) => Promise<void>
) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCodeChange = (value: string) => {
    const sanitizedValue = value.replace(/\D/g, "").slice(0, 6);
    setCode(sanitizedValue);
    if (error) setError("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form submitted with code:", code);

    if (!code) {
      setError("Code is required");
      return;
    }

    if (code.length === 6) {
      setIsSubmitting(true);
      setError("");
      try {
        console.log("Attempting authentication...");
        await onAuthenticate(code);
        console.log("Authentication successful");
      } catch (err) {
        console.error("Authentication error in form:", err);
        setError(
          err instanceof Error
            ? err.message
            : "Authentication failed. Please try again."
        );
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setError("Please enter a valid 6-digit code");
    }
  };

  return {
    code,
    error,
    isSubmitting,
    handleCodeChange,
    handleSubmit,
  };
};
