import React from "react";
import { Email } from "../../types/email";
import { InboxHeader } from "./InboxHeader";
import { InboxList } from "./InboxList";
import { EmptyInbox } from "./EmptyInbox";

interface InboxContainerProps {
  emails: Email[];
  refreshing: boolean;
  onRefresh: () => void;
  onAnalyzeEmail: (email: Email) => void;
  analyzingEmailId: string | null;
}

export const InboxContainer: React.FC<InboxContainerProps> = ({
  emails,
  refreshing,
  onRefresh,
  onAnalyzeEmail,
  analyzingEmailId,
}) => (
  <div className="space-y-2 bg-white/80 backdrop-blur-lg rounded-2xl shadow-xl transition-all duration-300 hover:shadow-2xl">
    <div className="p-8 pb-2">
      <InboxHeader
        messageCount={emails.length}
        onRefresh={onRefresh}
        refreshing={refreshing}
      />
    </div>
    {emails.length > 0 ? (
      <InboxList
        emails={emails}
        onAnalyzeEmail={onAnalyzeEmail}
        analyzingEmailId={analyzingEmailId}
      />
    ) : (
      <EmptyInbox />
    )}
  </div>
);
