import React from "react";
import { Button } from "../ui/Button";

interface BookmarkMenuButtonProps {
  onClick: () => void;
}

export const BookmarkMenuButton: React.FC<BookmarkMenuButtonProps> = ({
  onClick,
}) => (
  <Button
    variant="secondary"
    onClick={onClick}
    className="fixed top-4 right-4 mb-2 flex items-center gap-2 bg-white/90 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 border border-purple-100 px-4 py-2 rounded-xl hover:bg-purple-50">
    <svg
      className="w-5 h-5 text-purple-600"
      fill="currentColor"
      stroke="none"
      viewBox="0 0 24 24">
      <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
    </svg>
    <span className="text-purple-600 font-medium">Bookmarks</span>
  </Button>
);
