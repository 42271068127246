import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "../stores/useStore";
import { usePollingManager } from "../hooks/usePollingManager";
import { useEmailInitializer } from "../hooks/useEmailInitializer";
import { useAiAnalysis } from "../hooks/useAiAnalysis";
import { InboxSection } from "./InboxSection";
import { BookmarkedEmailsMenu } from "./BookmarkedEmailsMenu";
import { MainEmailSection } from "./email/MainEmailSection";
import { BookmarkMenuButton } from "./email/BookmarkMenuButton";
import { AddCustomEmailButton } from "./email/AddCustomEmailButton";
import { CustomEmailPopup } from "./email/CustomEmailPopup";
import { API_BASE_URL } from "../config/constants";
import { EmailAiAnalysis } from "./email/EmailAiAnalysis";

interface CustomEmailResponse {
  success: boolean;
  data?: {
    email: string;
    expiresAt: string;
  };
  error?: string;
}

interface VerificationItem {
  value: string;
  confidence: number;
}

export const EmailSection: React.FC = () => {
  const {
    emailContent,
    refreshing,
    bookmarkedEmails,
    loadingBookmarks,
    fetchEmailContent,
    unbookmarkEmail,
    setCurrentEmail,
    loadBookmarkedEmails,
    getRemark,
  } = useStore();

  const [isBookmarksMenuOpen, setIsBookmarksMenuOpen] = useState(false);
  const [isAddEmailOpen, setIsAddEmailOpen] = useState(false);
  const [isCreatingCustomEmail, setIsCreatingCustomEmail] = useState(false);

  const {
    analysisOpen,
    currentAnalysis,
    analyzingEmailId,
    analyzeEmail,
    closeAnalysis,
  } = useAiAnalysis();

  useEmailInitializer();
  usePollingManager();

  useEffect(() => {
    loadBookmarkedEmails();
  }, [loadBookmarkedEmails]);

  const handleBookmarksMenuToggle = useCallback(async () => {
    const newIsOpen = !isBookmarksMenuOpen;
    setIsBookmarksMenuOpen(newIsOpen);
    if (newIsOpen) {
      await loadBookmarkedEmails();
    }
  }, [isBookmarksMenuOpen, loadBookmarkedEmails]);

  const handleAddEmailToggle = useCallback(() => {
    setIsAddEmailOpen(!isAddEmailOpen);
  }, []);

  const handleCustomEmailSubmit = useCallback(
    async (email: string) => {
      setIsCreatingCustomEmail(true);
      try {
        const response = await fetch(`${API_BASE_URL}/custom-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });

        const data: CustomEmailResponse = await response.json();

        if (!data.success || !data.data) {
          throw new Error(data.error || "Failed to create custom email");
        }

        setCurrentEmail(data.data.email, data.data.expiresAt);
        setIsAddEmailOpen(false);
        await fetchEmailContent();
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        throw new Error("An unexpected error occurred");
      } finally {
        setIsCreatingCustomEmail(false);
      }
    },
    [setCurrentEmail, fetchEmailContent]
  );

  const handleSelectBookmarkedEmail = async (email: string) => {
    const bookmark = bookmarkedEmails.find((item) => item.email === email);
    if (bookmark) {
      setCurrentEmail(email, bookmark.metadata.expiresAt);
      await getRemark(email);
      await fetchEmailContent();
      setIsBookmarksMenuOpen(false);
    }
  };

  return (
    <>
      <BookmarkedEmailsMenu
        isOpen={isBookmarksMenuOpen}
        bookmarkedEmails={bookmarkedEmails}
        onClose={() => setIsBookmarksMenuOpen(false)}
        onSelectEmail={handleSelectBookmarkedEmail}
        onUnbookmark={unbookmarkEmail}
        loading={loadingBookmarks}
      />

      <CustomEmailPopup
        isOpen={isAddEmailOpen}
        onClose={() => setIsAddEmailOpen(false)}
        onConfirm={handleCustomEmailSubmit}
        isLoading={isCreatingCustomEmail}
      />

      <EmailAiAnalysis
        isOpen={analysisOpen}
        onClose={closeAnalysis}
        analysis={
          currentAnalysis as {
            links?: VerificationItem[];
            codes?: VerificationItem[];
          } | null
        }
        loading={!!analyzingEmailId}
      />

      <BookmarkMenuButton onClick={handleBookmarksMenuToggle} />
      <AddCustomEmailButton onClick={handleAddEmailToggle} />

      <div className="max-w-4xl mx-auto space-y-8">
        <MainEmailSection />
        <InboxSection
          emails={emailContent}
          refreshing={refreshing}
          onRefresh={fetchEmailContent}
          onAnalyzeEmail={analyzeEmail}
          analyzingEmailId={analyzingEmailId}
        />
      </div>
    </>
  );
};
