import React from "react";
import { Email } from "../types/email";
import { InboxContainer } from "./inbox/InboxContainer";

interface InboxSectionProps {
  emails: Email[];
  refreshing: boolean;
  onRefresh: () => void;
  onAnalyzeEmail: (email: Email) => void;
  analyzingEmailId: string | null;
}

export const InboxSection: React.FC<InboxSectionProps> = ({
  emails,
  refreshing,
  onRefresh,
  onAnalyzeEmail,
  analyzingEmailId,
}) => (
  <InboxContainer
    emails={emails}
    refreshing={refreshing}
    onRefresh={onRefresh}
    onAnalyzeEmail={onAnalyzeEmail}
    analyzingEmailId={analyzingEmailId}
  />
);
