import React from "react";
import { Button } from "./ui/Button";
import { useAuthForm } from "../hooks/useAuthForm";

interface AuthPageProps {
  onAuthenticate: (code: string) => Promise<void>;
}

export const AuthPage: React.FC<AuthPageProps> = ({ onAuthenticate }) => {
  const { code, error, isSubmitting, handleCodeChange, handleSubmit } =
    useAuthForm(onAuthenticate);

  console.log("AuthPage render - code:", code, "error:", error);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-100 via-purple-50 to-pink-100">
      <div className="w-full max-w-md p-8 space-y-6 shadow-xl bg-white/80 backdrop-blur-lg rounded-2xl">
        <AuthHeader />
        <AuthForm
          code={code}
          error={error}
          isSubmitting={isSubmitting}
          onCodeChange={handleCodeChange}
          onSubmit={handleSubmit}
        />
        <AuthFooter />
      </div>
    </div>
  );
};

const AuthHeader: React.FC = () => (
  <div className="space-y-2 text-center">
    <h2 className="text-3xl font-bold text-transparent bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text">
      Authentication Required
    </h2>
    <p className="text-gray-600">
      Please enter your TOTP code to access the email service
    </p>
  </div>
);

interface AuthFormProps {
  code: string;
  error: string;
  isSubmitting: boolean;
  onCodeChange: (value: string) => void;
  onSubmit: (e: React.FormEvent) => void;
}

const AuthForm: React.FC<AuthFormProps> = ({
  code,
  error,
  isSubmitting,
  onCodeChange,
  onSubmit,
}) => (
  <form onSubmit={onSubmit} className="space-y-6">
    <div className="space-y-2">
      <div className="flex justify-center">
        <input
          type="text"
          value={code}
          onChange={(e) => onCodeChange(e.target.value)}
          className="block w-48 px-4 py-3 text-2xl tracking-widest text-center transition-all duration-200 border-2 border-purple-200 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
          placeholder="000000"
          maxLength={6}
          autoFocus
        />
      </div>
      {error && <ErrorMessage message={error} />}
    </div>

    <Button
      type="submit"
      disabled={isSubmitting || code.length !== 6}
      loading={isSubmitting}
      className="w-full px-4 py-3">
      Verify
    </Button>
  </form>
);

const ErrorMessage: React.FC<{ message: string }> = ({ message }) => (
  <div className="flex items-center justify-center p-3 space-x-2 text-red-500 rounded-lg bg-red-50 animate-fadeIn">
    <svg
      className="w-5 h-5"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <span>{message}</span>
  </div>
);

const AuthFooter: React.FC = () => (
  <div className="text-sm text-center text-gray-500">
    Enter the 6-digit code from your authenticator app
  </div>
);
