import { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config/constants";

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return sessionStorage.getItem("authenticated") === "true";
  });

  const handleAuthentication = async (code: string) => {
    try {
      console.log("Sending auth request with code:", code);
      const response = await axios.post(
        `${API_BASE_URL}/totp/verify`,
        { code },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Auth response:", response.data);

      if (response.data.success) {
        setIsAuthenticated(true);
        sessionStorage.setItem("authenticated", "true");
        return;
      }

      throw new Error("Authentication failed");
    } catch (err) {
      console.error("Auth error:", err);

      if (axios.isAxiosError(err)) {
        const errorMessage =
          err.response?.data?.error || "Invalid authentication code";
        console.error("Axios error:", errorMessage);
        throw new Error(errorMessage);
      }
      throw err;
    }
  };

  return { isAuthenticated, handleAuthentication };
};
