import React, { useMemo } from "react";
import DOMPurify from "dompurify";

interface EmailContentDisplayProps {
  rawContent: string;
  preview?: boolean;
}

interface ParsedEmail {
  html?: string;
  text?: string;
  preview?: string;
}

const decodeQuotedPrintable = (str: string): string => {
  // Replace soft line breaks
  str = str.replace(/=\r\n/g, "");
  str = str.replace(/=\n/g, "");
  str = str.replace(/=$/g, "");

  // Decode hex characters
  return str.replace(/=([A-F0-9]{2})/gi, (_, p1) =>
    String.fromCharCode(parseInt(p1, 16))
  );
};

export const parseEmail = (rawContent: string): ParsedEmail => {
  try {
    // Split into parts by boundary if it exists
    const boundaryMatch = rawContent.match(/boundary="?([^"\r\n]+)"?/i);
    if (boundaryMatch) {
      const boundary = boundaryMatch[1];
      const parts = rawContent.split("--" + boundary);

      let htmlContent = "";
      let textContent = "";

      parts.forEach((part) => {
        if (part.includes("Content-Type: text/html")) {
          const encoding = part.includes("quoted-printable")
            ? "quoted-printable"
            : "plain";
          const content = part.split("\r\n\r\n").slice(1).join("\r\n\r\n");
          htmlContent =
            encoding === "quoted-printable"
              ? decodeQuotedPrintable(content)
              : content;
        } else if (part.includes("Content-Type: text/plain")) {
          const encoding = part.includes("quoted-printable")
            ? "quoted-printable"
            : "plain";
          const content = part.split("\r\n\r\n").slice(1).join("\r\n\r\n");
          textContent =
            encoding === "quoted-printable"
              ? decodeQuotedPrintable(content)
              : content;
        }
      });

      // Create preview from the decoded content
      const preview = (htmlContent || textContent)
        .replace(/<[^>]+>/g, " ")
        .replace(/\s+/g, " ")
        .trim()
        .slice(0, 200);

      return {
        html: htmlContent || undefined,
        text: textContent || undefined,
        preview: preview.length > 197 ? `${preview}...` : preview,
      };
    }

    // If no boundary found, treat as single part
    const isQuotedPrintable = rawContent.includes(
      "Content-Transfer-Encoding: quoted-printable"
    );
    const content = rawContent.split("\r\n\r\n").slice(1).join("\r\n\r\n");
    const decodedContent = isQuotedPrintable
      ? decodeQuotedPrintable(content)
      : content;

    const isHtml = rawContent.includes("Content-Type: text/html");

    return {
      html: isHtml ? decodedContent : undefined,
      text: !isHtml ? decodedContent : undefined,
      preview: decodedContent
        .replace(/<[^>]+>/g, " ")
        .replace(/\s+/g, " ")
        .trim()
        .slice(0, 200),
    };
  } catch (error) {
    console.error("Failed to parse email:", error);
    return {
      text: rawContent,
      preview: rawContent.slice(0, 200),
    };
  }
};

const createPreviewText = (parsedContent: ParsedEmail): string => {
  if (parsedContent.html) {
    // If it's a complex HTML email (contains tables, images, or multiple divs)
    const isComplexHtml =
      parsedContent.html.includes("<table") ||
      parsedContent.html.includes("<img") ||
      (parsedContent.html.match(/<div/g) || []).length > 3;

    if (isComplexHtml) {
      return "Rich HTML message - Click to view";
    }
  }

  // For simple emails, use the existing preview
  return parsedContent.preview || "No preview available";
};

export const EmailContentDisplay: React.FC<EmailContentDisplayProps> = ({
  rawContent,
  preview = false,
}) => {
  const parsedContent = useMemo(() => parseEmail(rawContent), [rawContent]);

  if (!parsedContent) {
    return <div className="text-gray-500">No content available</div>;
  }

  // Preview mode
  if (preview) {
    return (
      <div className="text-gray-600 text-sm line-clamp-2">
        {createPreviewText(parsedContent)}
      </div>
    );
  }

  // Full content display
  if (parsedContent.html) {
    return (
      <div
        className="prose max-w-none email-content"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(parsedContent.html, {
            ADD_TAGS: ["style"],
            ADD_ATTR: ["class", "id", "style"],
          }),
        }}
      />
    );
  }

  // Format plain text with proper styling
  const formattedText = (parsedContent.text || "")
    .split("\n")
    .map((line, index) => {
      if (line.startsWith(">")) {
        return (
          <div
            key={index}
            className="pl-4 border-l-4 border-gray-200 text-gray-600">
            {line.substring(1)}
          </div>
        );
      }

      if (line.trim() === "") {
        return <div key={index} className="h-4" />;
      }

      return (
        <div key={index} className="mb-2">
          {line}
        </div>
      );
    });

  return (
    <div className="whitespace-pre-wrap font-sans text-sm space-y-1">
      {formattedText}
    </div>
  );
};
