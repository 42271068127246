import React from "react";
import { Button } from "./ui/Button";

interface EmailDisplayProps {
  email: string;
  isBookmarked: boolean;
  onCopy: () => void;
  onBookmark: (email: string) => void;
  copied: boolean;
  bookmarkLoading: boolean;
}

const CopyIcon: React.FC = () => (
  <svg
    className="w-5 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
    />
  </svg>
);

const CopiedIcon: React.FC = () => (
  <svg
    className="w-5 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 13l4 4L19 7"
    />
  </svg>
);

const BookmarkIcon: React.FC<{ isBookmarked: boolean }> = ({
  isBookmarked,
}) => (
  <svg
    className="w-5 h-5"
    fill={isBookmarked ? "currentColor" : "none"}
    stroke="currentColor"
    viewBox="0 0 24 24">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
    />
  </svg>
);

export const RemarkIcon: React.FC = () => (
  <svg
    className="w-5 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
    />
  </svg>
);

export const EmailDisplay: React.FC<EmailDisplayProps> = ({
  email,
  isBookmarked,
  onCopy,
  onBookmark,
  copied,
  bookmarkLoading,
}) => {
  if (!email) return null;

  return (
    <div className="relative p-6 overflow-hidden transition-all border-2 border-purple-200 group bg-gray-50 rounded-xl hover:bg-gray-100">
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-3">
          <div className="flex-shrink-0 p-2 bg-purple-100 rounded-lg">
            <svg
              className="w-6 h-6 text-purple-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
          </div>
          <div className="font-mono text-lg font-medium text-gray-800 break-all">
            {email}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="secondary"
            onClick={() => onBookmark(email)}
            disabled={bookmarkLoading}
            loading={bookmarkLoading}
            className={`p-2 transition-transform ${
              bookmarkLoading
                ? "opacity-50 cursor-not-allowed"
                : "hover:scale-105"
            }`}
            title={isBookmarked ? "Bookmarked" : "Bookmark"}>
            <BookmarkIcon isBookmarked={isBookmarked} />
          </Button>
          <Button
            variant="secondary"
            onClick={onCopy}
            className="p-2 transition-transform hover:scale-105"
            title={copied ? "Copied!" : "Copy"}>
            {copied ? <CopiedIcon /> : <CopyIcon />}
          </Button>
        </div>
      </div>
    </div>
  );
};
