import React, { useMemo } from "react";

interface EmailFilterChipsProps {
  senders: string[];
  selectedSenders: string[];
  onToggleSender: (sender: string) => void;
}

// Function to generate a consistent gradient colors based on string
const generateGradientColors = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate two different hues for gradient
  const hue1 = hash % 360;
  const hue2 = (hue1 + 30) % 360; // Offset by 30 degrees for nice contrast

  return {
    bg: `linear-gradient(135deg, 
      hsl(${hue1}, 70%, 97%) 0%, 
      hsl(${hue2}, 70%, 97%) 100%)`,
    selectedBg: `linear-gradient(135deg, 
      hsl(${hue1}, 70%, 95%) 0%, 
      hsl(${hue2}, 70%, 95%) 100%)`,
    text: `hsl(${hue1}, 70%, 25%)`,
    border: `hsl(${hue1}, 70%, 85%)`,
    selectedBorder: `hsl(${hue1}, 70%, 75%)`,
  };
};

export const EmailFilterChips: React.FC<EmailFilterChipsProps> = ({
  senders,
  selectedSenders,
  onToggleSender,
}) => {
  if (senders.length === 0) return null;

  // Memoize color generation
  const senderColors = useMemo(
    () =>
      Object.fromEntries(
        senders.map((sender) => [sender, generateGradientColors(sender)])
      ),
    [senders]
  );

  return (
    <div className="flex flex-wrap gap-2 items-center">
      <span className="text-sm text-gray-500 mr-2">Filter by sender:</span>
      <div className="flex flex-wrap gap-1.5">
        {senders.map((sender) => {
          const colors = senderColors[sender];
          const isSelected = selectedSenders.includes(sender);
          return (
            <button
              key={sender}
              onClick={() => onToggleSender(sender)}
              style={{
                background: isSelected ? colors.selectedBg : colors.bg,
                color: colors.text,
                borderColor: isSelected ? colors.selectedBorder : colors.border,
              }}
              className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium 
                transition-all duration-200 hover:shadow-md border
                ${
                  isSelected
                    ? "ring-1 ring-offset-1"
                    : "hover:shadow-lg hover:scale-[1.02]"
                }`}>
              <span className="truncate max-w-[200px]">{sender}</span>
              {isSelected && (
                <svg
                  className="w-4 h-4 ml-1.5"
                  style={{ color: colors.text }}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};
