import React from "react";
import { Button } from "../ui/Button";

interface RemarkActionsProps {
  hasRemark: boolean;
  onEdit: () => void;
  onRemove: () => void;
  loading: boolean;
}

export const RemarkActions: React.FC<RemarkActionsProps> = ({
  hasRemark,
  onEdit,
  onRemove,
  loading,
}) => (
  <div className="flex gap-2">
    {hasRemark && (
      <Button
        variant="secondary"
        onClick={onRemove}
        loading={loading}
        className="text-sm">
        Clear
      </Button>
    )}
    <Button variant="secondary" onClick={onEdit} className="text-sm">
      {hasRemark ? "Edit" : "Add"}
    </Button>
  </div>
);
