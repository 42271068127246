import React, { useEffect } from "react";
import { useStore } from "../../stores/useStore";
import { EmailHeader } from "./EmailHeader";
import { EmailDisplay } from "../EmailDisplay";
import { RemarkSection } from "./RemarkSection";
import { EmailActions } from "./EmailActions";
import { EmptyEmailState } from "./EmptyEmailState";
import { useEmailActions } from "../../hooks/useEmailActions";
import { useBookmarkState } from "../../hooks/useBookmarkState";

export const EmailContainer: React.FC = () => {
  const {
    currentEmail,
    expiresAt,
    isBookmarked,
    loading,
    remarks,
    loadingRemark,
    generateNewEmail,
    getRemark,
  } = useStore();

  const { isProcessing, handleBookmark } = useBookmarkState();

  useEffect(() => {
    if (currentEmail && !remarks[currentEmail]) {
      getRemark(currentEmail);
    }
  }, [currentEmail, getRemark]);

  const {
    isEditingRemark,
    remarkInput,
    isCopied,
    handleRemarkChange,
    handleRemarkSubmit,
    handleRemarkRemove,
    handleCopy,
    handleEditStart,
    handleEditCancel,
  } = useEmailActions();

  const isExpired = expiresAt ? new Date(expiresAt) <= new Date() : false;

  if (!currentEmail) {
    return <EmptyEmailState onGenerate={generateNewEmail} loading={loading} />;
  }

  return (
    <div className="space-y-6 animate-fadeIn">
      <EmailHeader
        isExpired={isExpired}
        expiresAt={expiresAt}
        isBookmarked={isBookmarked}
      />

      <EmailDisplay
        email={currentEmail}
        isBookmarked={isBookmarked}
        onCopy={handleCopy}
        onBookmark={handleBookmark}
        copied={isCopied}
        bookmarkLoading={isProcessing}
      />

      <RemarkSection
        currentEmail={currentEmail}
        remarks={remarks}
        loadingRemark={loadingRemark}
        isEditingRemark={isEditingRemark}
        remarkInput={remarkInput}
        onRemarkChange={handleRemarkChange}
        onEditStart={handleEditStart}
        onEditCancel={handleEditCancel}
        onRemarkSubmit={handleRemarkSubmit}
        onRemarkRemove={handleRemarkRemove}
      />

      <EmailActions onGenerateNew={generateNewEmail} loading={loading} />
    </div>
  );
};
