import { useEffect } from "react";
import { useStore } from "../stores/useStore";

export const useEmailInitializer = () => {
  const { initializeFromStorage, startPolling } = useStore();

  useEffect(() => {
    const initialize = async () => {
      const hasStoredEmail = await initializeFromStorage();
      if (hasStoredEmail) {
        startPolling();
      }
    };

    initialize();
  }, [initializeFromStorage, startPolling]);
};
