// Get the base URL from environment variables, defaulting to the production URL if not set
const API_URL =
  import.meta.env.VITE_API_BASE_URL ||
  "https://email-api.timc-cloud.workers.dev/api";

// In development, if the URL starts with '/api', use the relative path
export const API_BASE_URL = API_URL.startsWith("/api")
  ? API_URL // Use relative path in development
  : "https://email-api.timc-cloud.workers.dev/api"; // Use full URL in production

export const AUTH_BASE_URL = API_BASE_URL;

// Add email domain constant
export const EMAIL_DOMAIN = import.meta.env.VITE_EMAIL_DOMAIN || "rxmail.site";

// For debugging
console.log("Current API URL:", API_BASE_URL);
console.log("Environment:", import.meta.env.MODE);
console.log("VITE_API_BASE_URL:", import.meta.env.VITE_API_BASE_URL);
console.log("EMAIL_DOMAIN:", EMAIL_DOMAIN);
