import React from "react";
import { Button } from "./ui/Button";
import type { BookmarkedEmail } from "../types/email";

interface BookmarkedEmailsMenuProps {
  isOpen: boolean;
  bookmarkedEmails: BookmarkedEmail[];
  onClose: () => void;
  onSelectEmail: (email: string) => void;
  onUnbookmark: (email: string) => Promise<void>;
  loading?: boolean;
}

export const BookmarkedEmailsMenu: React.FC<BookmarkedEmailsMenuProps> = ({
  isOpen,
  bookmarkedEmails,
  onClose,
  onSelectEmail,
  onUnbookmark,
  loading = false,
}) => {
  if (!isOpen) return null;

  const showLoading = loading && bookmarkedEmails.length === 0;
  const showEmpty = !loading && bookmarkedEmails.length === 0;

  return (
    <>
      <div
        className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40"
        onClick={onClose}
      />

      <div className="fixed inset-y-0 left-0 z-50 w-80 bg-white/95 backdrop-blur-md shadow-2xl animate-slideIn">
        <div className="flex items-center justify-between p-4 border-b border-purple-100 bg-gradient-to-r from-purple-50 to-pink-50">
          <h2 className="text-lg font-semibold text-gray-800 flex items-center gap-2">
            <svg
              className="w-5 h-5 text-purple-600"
              fill="currentColor"
              stroke="none"
              viewBox="0 0 24 24">
              <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
            </svg>
            Bookmarked Emails
          </h2>
          <Button
            variant="secondary"
            onClick={onClose}
            className="p-2 hover:bg-purple-50">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Button>
        </div>

        <div className="p-4 space-y-3 overflow-y-auto max-h-[calc(100vh-64px)]">
          {showLoading ? (
            <div className="flex flex-col items-center justify-center py-12 space-y-4">
              <div className="w-6 h-6 border-2 border-purple-600 rounded-full animate-spin border-t-transparent" />
              <p className="text-sm text-gray-500">Loading bookmarks...</p>
            </div>
          ) : showEmpty ? (
            <div className="flex flex-col items-center justify-center py-12 text-gray-500 space-y-4">
              <svg
                className="w-12 h-12 text-purple-200"
                fill="currentColor"
                stroke="none"
                viewBox="0 0 24 24">
                <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
              </svg>
              <p>No bookmarked emails</p>
            </div>
          ) : (
            <div className="relative space-y-3">
              {loading && (
                <div className="absolute top-0 right-0 p-2">
                  <div className="w-4 h-4 border-2 border-purple-600 rounded-full animate-spin border-t-transparent" />
                </div>
              )}
              {bookmarkedEmails.map((item) => (
                <div
                  key={item.email}
                  className="p-4 space-y-2 bg-white rounded-xl border border-purple-100 hover:border-purple-200 transition-all duration-300 hover:shadow-md">
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => onSelectEmail(item.email)}
                      className="text-left font-mono text-sm text-gray-800 hover:text-purple-600 transition-colors">
                      {item.email}
                    </button>
                    <Button
                      variant="secondary"
                      onClick={() => onUnbookmark(item.email)}
                      className="p-2 hover:bg-red-50 hover:text-red-500 transition-colors">
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        stroke="none"
                        viewBox="0 0 24 24">
                        <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                      </svg>
                    </Button>
                  </div>
                  {item.metadata?.remark && (
                    <div className="text-sm text-gray-600 bg-purple-50/50 px-3 py-2 rounded-lg">
                      {item.metadata.remark}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
