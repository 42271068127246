import React from "react";
import { RemarkIcon } from "../EmailDisplay";
import { RemarkActions } from "./RemarkActions";
import { RemarkEditForm } from "./RemarkEditForm";

interface RemarkSectionProps {
  currentEmail: string | null;
  remarks: Record<string, string>;
  loadingRemark: boolean;
  isEditingRemark: boolean;
  remarkInput: string;
  onRemarkChange: (value: string) => void;
  onEditStart: () => void;
  onEditCancel: () => void;
  onRemarkSubmit: () => void;
  onRemarkRemove: () => void;
}

export const RemarkSection: React.FC<RemarkSectionProps> = ({
  currentEmail,
  remarks,
  loadingRemark,
  isEditingRemark,
  remarkInput,
  onRemarkChange,
  onEditStart,
  onEditCancel,
  onRemarkSubmit,
  onRemarkRemove,
}) => (
  <div className="flex items-center justify-between p-4 transition-all duration-200 border border-purple-100 rounded-xl bg-purple-50/50 hover:bg-purple-50">
    <div className="flex items-center gap-3">
      <div className="flex items-center gap-2 text-purple-600">
        <RemarkIcon />
        <span className="font-medium">Remark</span>
      </div>
      {!isEditingRemark && (
        <span className="text-sm text-gray-600">
          {loadingRemark ? (
            <span className="animate-pulse">Loading...</span>
          ) : (
            remarks[currentEmail || ""] || "No remark added yet"
          )}
        </span>
      )}
    </div>
    {!isEditingRemark ? (
      <RemarkActions
        hasRemark={!!remarks[currentEmail || ""]}
        onEdit={onEditStart}
        onRemove={onRemarkRemove}
        loading={loadingRemark}
      />
    ) : (
      <RemarkEditForm
        remarkInput={remarkInput}
        onRemarkChange={onRemarkChange}
        onCancel={onEditCancel}
        onSubmit={onRemarkSubmit}
        loading={loadingRemark}
      />
    )}
  </div>
);
