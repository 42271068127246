import { useState } from "react";
import { AI_CONFIG } from "../config/ai";
import { Email } from "../types/email";
import { parseEmail } from "../components/email/EmailContentDisplay";

interface VerificationItem {
  value: string;
  confidence: number;
}

export const useAiAnalysis = () => {
  const [analysisOpen, setAnalysisOpen] = useState(false);
  const [currentAnalysis, setCurrentAnalysis] = useState<{
    links?: VerificationItem[];
    codes?: VerificationItem[];
  } | null>(null);
  const [analyzingEmailId, setAnalyzingEmailId] = useState<string | null>(null);

  const parseAIResponse = (content: string) => {
    console.log("Raw AI Response:", content);

    if (content.trim() === "NO_VERIFICATION_INFO") {
      return { links: [], codes: [] };
    }

    const links: VerificationItem[] = [];
    const codes: VerificationItem[] = [];

    // Split content into sections
    const sections = content.split(/LINKS:|CODES:/);

    if (sections.length > 1) {
      // Extract links with confidence scores
      const linksSection = sections[1];
      if (linksSection) {
        const linkMatches = linksSection.match(/(\d+)\s+(https?:\/\/[^\s]+)/g);
        if (linkMatches) {
          linkMatches.forEach((match) => {
            const [score, url] = match.split(/\s+/);
            if (url?.startsWith("http")) {
              links.push({
                value: url,
                confidence: parseInt(score, 10),
              });
            }
          });
        }
      }

      // Extract codes with confidence scores
      const codesSection = sections[2];
      if (codesSection) {
        const codeMatches = codesSection.match(/(\d+)\s+([A-Za-z0-9-]{4,32})/g);
        if (codeMatches) {
          codeMatches.forEach((match) => {
            const [score, code] = match.split(/\s+/);
            codes.push({
              value: code,
              confidence: parseInt(score, 10),
            });
          });
        }
      }
    }

    // Sort by confidence score descending
    const sortByConfidence = (a: VerificationItem, b: VerificationItem) =>
      b.confidence - a.confidence;

    return {
      links: links.sort(sortByConfidence),
      codes: codes.sort(sortByConfidence),
    };
  };

  const analyzeEmail = async (email: Email) => {
    setAnalyzingEmailId(email.id);
    setAnalysisOpen(true);
    setCurrentAnalysis(null);

    try {
      const parsedContent = parseEmail(email.rawContent);
      const textContent =
        parsedContent.text ||
        parsedContent.html?.replace(/<[^>]+>/g, " ") ||
        "";

      console.log("Analyzing email:", {
        subject: email.subject,
        from: email.from,
        contentLength: textContent.length,
      });

      const prompt = AI_CONFIG.PROMPT_TEMPLATE.replace(
        "{{subject}}",
        email.subject
      )
        .replace("{{from}}", email.from)
        .replace("{{content}}", textContent);

      const response = await fetch(AI_CONFIG.OPENROUTER_API_URL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${AI_CONFIG.OPENROUTER_API_KEY}`,
          "HTTP-Referer": window.location.origin,
          "X-Title": "Email Analysis App",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: AI_CONFIG.MODEL,
          messages: [
            {
              role: "user",
              content: prompt,
            },
          ],
          transforms: ["middle-out"],
          route: "fallback",
        }),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error("API Error:", {
          status: response.status,
          statusText: response.statusText,
          error: errorData,
        });
        throw new Error(`API request failed: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("AI Response:", data);

      if (!data.choices?.[0]?.message?.content) {
        throw new Error("Invalid API response format");
      }

      const analysis = parseAIResponse(data.choices[0].message.content);
      console.log("Parsed Analysis:", analysis);

      if (!analysis.links && !analysis.codes) {
        throw new Error("Failed to parse AI response");
      }

      setCurrentAnalysis(analysis);
    } catch (error) {
      console.error("Failed to analyze email:", error);
      setCurrentAnalysis(null);
    } finally {
      setAnalyzingEmailId(null);
    }
  };

  const closeAnalysis = () => {
    setAnalysisOpen(false);
    setCurrentAnalysis(null);
  };

  return {
    analysisOpen,
    currentAnalysis,
    analyzingEmailId,
    analyzeEmail,
    closeAnalysis,
  };
};
