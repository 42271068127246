import React from "react";

export const EmptyInbox: React.FC = () => (
  <div className="flex flex-col items-center justify-center py-12 space-y-4 text-gray-500">
    <svg
      className="w-16 h-16 text-gray-400"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
      />
    </svg>
    <p className="text-lg">No messages received yet</p>
    <p className="text-sm">
      Messages sent to this address will appear here automatically
    </p>
  </div>
);
