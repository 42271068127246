import { StateCreator } from "zustand";
import { PollingState } from "../../types/store";
import { EmailSlice } from "./emailSlice";

const POLLING_INTERVAL = 10000;

export interface PollingSlice extends PollingState {
  startPolling: () => void;
  stopPolling: () => void;
}

type StoreSlice = PollingSlice & EmailSlice;

export const createPollingSlice: StateCreator<
  StoreSlice,
  [],
  [],
  PollingSlice
> = (set, get) => ({
  isPolling: false,
  refreshing: false,
  pollingTimeout: null,

  startPolling: () => {
    get().stopPolling();

    const poll = async () => {
      const { currentEmail, expiresAt } = get();
      const isExpired = expiresAt && new Date(expiresAt) <= new Date();

      if (!currentEmail || isExpired) {
        get().stopPolling();
        return;
      }

      try {
        set({ refreshing: true });
        await get().fetchEmailContent();
      } catch (error) {
        console.error("Polling error:", error);
      } finally {
        set({ refreshing: false });
        const timeoutId = setTimeout(poll, POLLING_INTERVAL);
        set({ pollingTimeout: timeoutId, isPolling: true });
      }
    };

    set({ isPolling: true });
    poll();
  },

  stopPolling: () => {
    const { pollingTimeout } = get();
    if (pollingTimeout) {
      clearTimeout(pollingTimeout);
    }
    set({ isPolling: false, pollingTimeout: null });
  },
});
