import React, { useState } from "react";
import { Button } from "../ui/Button";
import { EMAIL_DOMAIN } from "../../config/constants";

interface CustomEmailPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (email: string) => Promise<void>;
  isLoading?: boolean;
}

export const CustomEmailPopup: React.FC<CustomEmailPopupProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading = false,
}) => {
  const [localPart, setLocalPart] = useState("");
  const [error, setError] = useState("");

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate local part only
    const localPartRegex = /^[a-zA-Z0-9._-]+$/;
    if (!localPartRegex.test(localPart)) {
      setError(
        "Invalid characters. Use only letters, numbers, dots, underscores, or hyphens"
      );
      return;
    }

    const fullEmail = `${localPart}@${EMAIL_DOMAIN}`;

    try {
      await onConfirm(fullEmail);
      setLocalPart("");
      setError("");
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unexpected error occurred");
      }
    }
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40"
        onClick={onClose}
      />
      <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md z-50">
        <div className="bg-white/95 backdrop-blur-md shadow-2xl rounded-2xl border border-purple-100 overflow-hidden animate-fadeIn">
          <div className="p-6 space-y-4">
            <div className="flex items-center justify-between border-b border-purple-100 pb-4">
              <h2 className="text-xl font-semibold text-gray-800 flex items-center gap-2">
                <svg
                  className="w-5 h-5 text-purple-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                Add Custom Email
              </h2>
              <Button
                variant="secondary"
                onClick={onClose}
                disabled={isLoading}
                className="p-2 hover:bg-purple-50">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </Button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <div className="relative">
                  <input
                    type="text"
                    value={localPart}
                    onChange={(e) => {
                      setLocalPart(e.target.value.toLowerCase());
                      setError("");
                    }}
                    placeholder="Enter username"
                    className="w-full px-4 py-3 text-gray-800 transition-all duration-200 bg-white border-2 border-purple-200 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                    autoFocus
                    disabled={isLoading}
                  />
                  <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">
                    @{EMAIL_DOMAIN}
                  </span>
                </div>
                {error && (
                  <div className="flex items-center gap-2 text-red-500 text-sm">
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>{error}</span>
                  </div>
                )}
              </div>

              <div className="flex justify-end gap-2">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={onClose}
                  disabled={isLoading}
                  className="px-4 py-2">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={!localPart || isLoading}
                  loading={isLoading}
                  className="px-4 py-2 flex items-center gap-2">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                  Add Email
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
