export const AI_CONFIG = {
  OPENROUTER_API_URL: "https://openrouter.ai/api/v1/chat/completions",
  OPENROUTER_API_KEY: import.meta.env.VITE_OPENROUTER_API_KEY,
  MODEL: import.meta.env.VITE_AI_MODEL || "google/gemini-flash-1.5",
  APP_NAME: "Email Analysis App",
  PROMPT_TEMPLATE: `Analyze this email and extract verification information with confidence scores.

For each link and code found, rate its confidence (0-100) based on:
- Context and surrounding text
- URL patterns (verify, confirm, activate, auth, etc.)
- Typical verification code patterns

Format response EXACTLY like this:
LINKS:
[confidence_score] [full_url]
(one per line, sorted by confidence, only include scores above 70)

CODES:
[confidence_score] [code]
(one per line, sorted by confidence, only include scores above 70)

Example:
LINKS:
95 https://example.com/verify?token=abc
80 https://example.com/confirm-email

CODES:
90 ABC123XYZ
85 123456

Only include links that are likely verification/authentication related.
If nothing found with confidence > 70, respond with "NO_VERIFICATION_INFO"

Email details:
Subject: {{subject}}
From: {{from}}
Content: {{content}}`,
};
