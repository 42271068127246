import { useAuth } from "./hooks/useAuth";
import { AuthPage } from "./components/AuthPage";
import { EmailSection } from "./components/EmailSection";

const App: React.FC = () => {
  const { isAuthenticated, handleAuthentication } = useAuth();

  if (!isAuthenticated) {
    return <AuthPage onAuthenticate={handleAuthentication} />;
  }

  return (
    <div className="min-h-screen transition-all duration-500 bg-gradient-to-br from-indigo-100 via-purple-50 to-pink-100">
      <div className="max-w-4xl px-4 py-12 mx-auto">
        <EmailSection />
      </div>
    </div>
  );
};

export default App;
