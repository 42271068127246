import { StateCreator } from "zustand";
import { BookmarkState, LoadingState } from "../../types/store";
import axios from "axios";
import { API_BASE_URL } from "../../config/constants";

export interface BookmarkSlice extends BookmarkState, LoadingState {
  bookmarkEmail: (email: string) => Promise<void>;
  unbookmarkEmail: (email: string) => Promise<void>;
  loadBookmarkedEmails: () => Promise<void>;
}

export const createBookmarkSlice: StateCreator<
  BookmarkSlice & {
    isBookmarked: boolean;
    currentEmail: string | null;
    generateNewEmail: () => Promise<void>;
  },
  [],
  [],
  BookmarkSlice
> = (set, get) => ({
  bookmarkedEmails: [],
  loadingBookmarks: false,
  loading: false,
  bookmarkLoading: false,
  error: null,

  bookmarkEmail: async (email: string) => {
    const state = get();

    if (state.bookmarkLoading || state.isBookmarked) {
      return;
    }

    try {
      set({ bookmarkLoading: true, error: null });

      // Make API call first
      await axios.post(`${API_BASE_URL}/bookmark`, { email });

      // Get fresh data from server
      const response = await axios.get(`${API_BASE_URL}/bookmarks`);
      const bookmarks = response.data.data;

      // Update state with server data
      set({
        isBookmarked: true,
        bookmarkedEmails: bookmarks,
      });

      // Update localStorage
      const stored = localStorage.getItem("tempEmail");
      if (stored) {
        const data = JSON.parse(stored);
        localStorage.setItem(
          "tempEmail",
          JSON.stringify({
            ...data,
            isBookmarked: true,
          })
        );
      }
    } catch (error) {
      console.error("Bookmark error:", error);
      set({
        error: "Failed to bookmark email",
        isBookmarked: false,
      });
      throw error;
    } finally {
      set({ bookmarkLoading: false });
    }
  },

  unbookmarkEmail: async (email: string) => {
    const state = get();

    if (state.bookmarkLoading) {
      return;
    }

    try {
      set({ bookmarkLoading: true, error: null });

      // Make API call first
      await axios.delete(`${API_BASE_URL}/bookmark`, { data: { email } });

      // Get fresh data from server
      const response = await axios.get(`${API_BASE_URL}/bookmarks`);
      const bookmarks = response.data.data;

      // Update state with server data
      set((state) => ({
        isBookmarked: email === state.currentEmail ? false : state.isBookmarked,
        bookmarkedEmails: bookmarks,
      }));

      // If unbookmarking current email
      if (email === get().currentEmail) {
        localStorage.removeItem("tempEmail");
        await get().generateNewEmail();
      }
    } catch (error) {
      console.error("Unbookmark error:", error);
      set({ error: "Failed to remove bookmark" });
      throw error;
    } finally {
      set({ bookmarkLoading: false });
    }
  },

  loadBookmarkedEmails: async () => {
    if (get().loadingBookmarks) {
      return;
    }

    try {
      set({ loadingBookmarks: true, error: null });

      const response = await axios.get(`${API_BASE_URL}/bookmarks`);
      const bookmarks = response.data.data;

      set((state) => {
        const isCurrentEmailBookmarked = bookmarks.some(
          (bookmark: { email: string }) => bookmark.email === state.currentEmail
        );
        return {
          bookmarkedEmails: bookmarks,
          isBookmarked: isCurrentEmailBookmarked,
        };
      });
    } catch (error) {
      console.error("Error loading bookmarks:", error);
      set({ error: "Failed to load bookmarks" });
      throw error;
    } finally {
      set({ loadingBookmarks: false });
    }
  },
});
