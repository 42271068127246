import React from "react";
import { Button } from "../ui/Button";

interface EmailActionsProps {
  onGenerateNew: () => void;
  loading: boolean;
}

export const EmailActions: React.FC<EmailActionsProps> = ({
  onGenerateNew,
  loading,
}) => (
  <div className="flex items-center justify-between pt-2">
    <div className="text-sm text-gray-500">
      All emails sent to this address will appear below
    </div>
    <Button
      onClick={onGenerateNew}
      disabled={loading}
      loading={loading}
      variant="secondary"
      className="flex items-center px-4 py-2 space-x-2">
      <svg
        className="w-5 h-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </svg>
      <span>New Email</span>
    </Button>
  </div>
);
