import React from "react";
import { Button } from "../ui/Button";

interface InboxHeaderProps {
  messageCount: number;
  onRefresh: () => void;
  refreshing: boolean;
}

export const InboxHeader: React.FC<InboxHeaderProps> = ({
  messageCount,
  onRefresh,
  refreshing,
}) => (
  <div className="flex items-center justify-between">
    <div className="space-y-1">
      <h2 className="text-2xl font-semibold text-gray-700">Inbox</h2>
      <p className="text-sm text-gray-500">
        {messageCount} {messageCount === 1 ? "message" : "messages"} received
      </p>
    </div>
    <Button
      variant="secondary"
      onClick={onRefresh}
      loading={refreshing}
      className="flex items-center gap-2 px-4 py-2">
      <svg
        className={`w-5 h-5 ${refreshing ? "animate-spin" : ""}`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
      <span>{refreshing ? "Refreshing..." : "Refresh"}</span>
    </Button>
  </div>
);
