import { StateCreator } from "zustand";
import { EmailState } from "../../types/store";

interface StoredEmailData {
  email: string;
  expiresAt: string;
  isBookmarked: boolean;
}

export interface StorageSlice {
  initializeFromStorage: () => Promise<boolean>;
  saveToStorage: (data: StoredEmailData) => void;
  clearStorage: () => void;
}

export const createStorageSlice: StateCreator<
  StorageSlice & EmailState & { getRemark: (email: string) => Promise<void> },
  [],
  [],
  StorageSlice
> = (set, get) => ({
  initializeFromStorage: async () => {
    const stored = localStorage.getItem("tempEmail");
    if (stored) {
      try {
        const data = JSON.parse(stored) as StoredEmailData;
        const isValid =
          data.isBookmarked || new Date(data.expiresAt) > new Date();

        if (isValid) {
          set({
            currentEmail: data.email,
            expiresAt: data.expiresAt,
            isBookmarked: data.isBookmarked,
          });

          // Load remark for the email
          await get().getRemark(data.email);
          return true;
        }
      } catch (error) {
        console.error("Error parsing stored email data:", error);
      }
    }
    return false;
  },

  saveToStorage: (data: StoredEmailData) => {
    try {
      localStorage.setItem("tempEmail", JSON.stringify(data));
    } catch (error) {
      console.error("Error saving to storage:", error);
    }
  },

  clearStorage: () => {
    localStorage.removeItem("tempEmail");
  },
});
