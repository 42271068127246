import React from "react";
import { Button } from "../ui/Button";

interface RemarkEditFormProps {
  remarkInput: string;
  onRemarkChange: (value: string) => void;
  onCancel: () => void;
  onSubmit: () => void;
  loading: boolean;
}

export const RemarkEditForm: React.FC<RemarkEditFormProps> = ({
  remarkInput,
  onRemarkChange,
  onCancel,
  onSubmit,
  loading,
}) => (
  <div className="flex-1 ml-4">
    <div className="flex gap-3">
      <input
        type="text"
        value={remarkInput}
        onChange={(e) => onRemarkChange(e.target.value)}
        placeholder="What is this email used for?"
        className="flex-1 px-3 py-1.5 text-sm border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
      />
      <Button variant="secondary" onClick={onCancel} className="text-sm">
        Cancel
      </Button>
      <Button onClick={onSubmit} loading={loading} className="text-sm">
        Save
      </Button>
    </div>
  </div>
);
