import React from "react";

interface TimeDisplayProps {
  expiresAt: string;
}

export const TimeDisplay: React.FC<TimeDisplayProps> = ({ expiresAt }) => {
  const [timeLeft, setTimeLeft] = React.useState<{
    minutes: number;
    seconds: number;
  }>(() => {
    // Calculate initial time left
    const now = new Date().getTime();
    const distance = new Date(expiresAt).getTime() - now;

    if (distance < 0) {
      return { minutes: 0, seconds: 0 };
    }

    const minutes = Math.floor(distance / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { minutes, seconds };
  });

  React.useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const distance = new Date(expiresAt).getTime() - now;

      if (distance < 0) {
        return { minutes: 0, seconds: 0 };
      }

      const minutes = Math.floor(distance / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      return { minutes, seconds };
    };

    // Update time immediately
    setTimeLeft(calculateTimeLeft());

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [expiresAt]);

  const formatNumber = (num: number): string => {
    return num.toString().padStart(2, "0");
  };

  return (
    <span className="font-medium">
      {formatNumber(timeLeft.minutes)}:{formatNumber(timeLeft.seconds)}
    </span>
  );
};
