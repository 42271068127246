import React, { useState, useMemo } from "react";
import { Email } from "../../types/email";
import { MessageDisplay } from "../MessageDisplay";
import { parseEmail } from "../email/EmailContentDisplay";
import { EmailFilterChips } from "./EmailFilterChips";

interface InboxListProps {
  emails: Email[];
  onAnalyzeEmail: (email: Email) => void;
  analyzingEmailId: string | null;
}

export const InboxList: React.FC<InboxListProps> = ({
  emails,
  onAnalyzeEmail,
  analyzingEmailId,
}) => {
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [selectedSenders, setSelectedSenders] = useState<string[]>([]);

  // Extract unique senders
  const uniqueSenders = useMemo(() => {
    return Array.from(new Set(emails.map((email) => email.from))).sort();
  }, [emails]);

  // Filter emails based on selected senders
  const filteredEmails = useMemo(() => {
    if (selectedSenders.length === 0) return emails;
    return emails.filter((email) => selectedSenders.includes(email.from));
  }, [emails, selectedSenders]);

  const handleToggleSender = (sender: string) => {
    setSelectedSenders((prev) =>
      prev.includes(sender)
        ? prev.filter((s) => s !== sender)
        : [...prev, sender]
    );
  };

  const handleEmailClick = (email: Email) => {
    // If it's a complex HTML email, open in new tab
    if (
      email.rawContent.includes("<table") ||
      email.rawContent.includes("<img") ||
      (email.rawContent.match(/<div/g) || []).length > 3
    ) {
      // Parse the email content first
      const parsedContent = parseEmail(email.rawContent);
      const htmlContent = parsedContent.html || parsedContent.text || "";

      // Create a new window with the email content
      const win = window.open("", "_blank");
      if (win) {
        win.document.write(`
          <!DOCTYPE html>
          <html>
            <head>
              <title>${email.subject}</title>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
              <style>
                body {
                  background-color: #f3f4f6;
                  padding: 2rem;
                }
                .email-container {
                  max-width: 48rem;
                  margin: 0 auto;
                  background-color: white;
                  border-radius: 0.5rem;
                  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                  padding: 2rem;
                }
                .email-header {
                  border-bottom: 1px solid #e5e7eb;
                  padding-bottom: 1.5rem;
                  margin-bottom: 1.5rem;
                }
                ${document.querySelector("style")?.innerHTML || ""}
                .email-content img {
                  max-width: 100%;
                  height: auto;
                }
              </style>
            </head>
            <body>
              <div class="email-container">
                <div class="email-header">
                  <h1 class="text-2xl font-bold mb-4">${email.subject}</h1>
                  <div class="text-gray-600">
                    <p class="mb-1"><strong>From:</strong> ${email.from}</p>
                    <p><strong>To:</strong> ${email.to}</p>
                  </div>
                </div>
                <div class="email-content">
                  ${htmlContent}
                </div>
              </div>
            </body>
          </html>
        `);
        win.document.close();
        return;
      }
    }

    // For simple emails, toggle expansion
    setExpandedId(email.id === expandedId ? null : email.id);
  };

  return (
    <>
      <div className="bg-gradient-to-b from-gray-50/70 to-white/50 backdrop-blur-sm rounded-b-2xl border-t border-gray-100/50">
        <div className="px-8 py-4">
          <EmailFilterChips
            senders={uniqueSenders}
            selectedSenders={selectedSenders}
            onToggleSender={handleToggleSender}
          />
        </div>
        <div className="px-8 pb-6">
          <div
            className="space-y-4 max-h-[calc(100vh-340px)] hide-scrollbar pt-2"
            style={{
              overflowY: "scroll",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}>
            {filteredEmails.map((email) => (
              <MessageDisplay
                key={email.id}
                message={email}
                expanded={email.id === expandedId}
                onClick={() => handleEmailClick(email)}
                onAiAnalyze={() => onAnalyzeEmail(email)}
                isAnalyzing={analyzingEmailId === email.id}
              />
            ))}
            {filteredEmails.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No emails match the selected filters
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
